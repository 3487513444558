/**
 * @name: 商城管理-协议管理接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-协议管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPage, IPageRes} from "@/apis/page";
import { IAgreement } from "./types";

export const agreementQueryApi = (params: IPage) => get<IPageRes<IAgreement[]>>("/admin/agreement/query", params)

export const agreementDetailApi = (id: string) => get<IAgreement>("/admin/agreement/detail/" + id)

export const agreementModifyApi = (data: Partial<IAgreement>) => postJ("/admin/agreement/modify", data)
