
/**
 * @name: 商城管理-协议管理
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-协议管理
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  agreementQueryApi,
  agreementDetailApi,
  agreementModifyApi
} from '@/apis/mall/agreement'
import {
  IAgreement
} from '@/apis/mall/agreement/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { IPage } from "@/apis/page";

@Component({})
export default class mallAgreement extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IAgreement[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IPage = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IAgreement> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    column: [
      {
        label: '序号',
        type: 'index',
        width: 50,
        align: "center",
        editHide: true
      },
      {
        label: '协议类型',
        prop: 'type',
        type: 'select',
        align: 'center',
        dicData: [
          {
            label: '用户协议',
            value: 1
          },
          {
            label: '隐私协议',
            value: 2
          },
          {
            label: '申报社区服务费协议',
            value: 3
          }
        ],
        editHide: true
      },
      {
        label: '协议名称',
        prop: 'agreementName',
        align: 'center',
        maxlength: 10,
        rules: [
          {
            required: true,
            message: '请输入协议名称',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '协议详情',
        prop: 'agreementDtl',
        align: 'center',
        editSlot: true,
        hide: true,
        rules: [
          {
            required: true,
            message: '请输入协议详情',
            trigger: 'blur'
          }
        ]
      }
    ]
  }

  openEdit (row: IAgreement, index: number) {
    agreementDetailApi(row.id).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index)
    })
  }

  rowEdit (form: IAgreement, done: Function, loading: Function) {
    agreementModifyApi(form).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  getList () {
    this.tableLoading = true

    agreementQueryApi(this.queryParam).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  created () {
    this.getList()
  }
}
